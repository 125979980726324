import React, { Fragment, useState, useEffect, useContext } from "react";
import { AppContext } from './context';
import { 
    Divider, 
    Button, 
    Dialog,
    DialogTitle,
    DialogContent,
    InputAdornment,
    DialogActions,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    Chip,
    Avatar,
    CircularProgress,
    Typography
} from "@material-ui/core";
import { getTokens, dynamicSort } from './../../helpers/handleTotle';
import { useFuzzy } from 'react-use-fuzzy';
import SearchIcon from '@material-ui/icons/Search';
import {Virtuoso} from 'react-virtuoso';

const allowed_tokens = ["ETH","REN","LINK","POLY","USDT","USDC"]

const ListContainer = ({listRef, style, children}) => {
    return (
      <List dense ref={listRef} style={style}>
        {children}
      </List>
    );
};

const TokenSelectorModal = () => {

    // state
    const [open, setOpen] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [selected_source, setSelectedSource] = useState(null);
    const [selected_destination, setSelectedDestination] = useState(null);
    const [loading, setLoading] = useState(true);

    // context
    const {state, dispatch} = useContext(AppContext);

    // lifecycle
    useEffect(() => {
        //console.log("State of modal", state)
        setOpen(state.is_modal_on)
        if(state.is_modal_on) {
            handleGetTokens();
        }
        if(state.source) {
            setSelectedSource(state.source.address);
        }
        if (state.destination) {
            setSelectedDestination(state.destination.address);
        }
    },[state]);

    // hooks
    const { result, keyword, search } = useFuzzy(tokens, {
        keys: ['name','address','symbol'],
    });

    // methods
    const toggleModal = () => {
        dispatch({ 
            type: 'TOGGLE_MODAL', 
            payload: !state.is_modal_on
        });
    };

    const handleGetTokens = async() => {
        const [error,response] = await getTokens();
        setLoading(false);
        if(error) return;
        let tokens = response.data.sort(dynamicSort("address"));
        setTokens(tokens);
        /*
        dispatch({ 
            type: 'SAVE_TOKENS', 
            payload: tokens
        });
        */
        //allowed_tokens.includes(token.symbol)
    }

    const selectToken = (token) => {
        if( (token.address || token.item.address ) === state.source.address) {
            return;
        }
        if( state.destination && (token.address || token.item.address ) === state.destination.address) {
            return;
        }
        if(state.select === "source") {
            dispatch({ 
                type: 'SET_SOURCE', 
                payload: token.item ? token.item : token
            });
        } else if(state.select === "destination") {
            dispatch({ 
                type: 'SET_DESTINATION', 
                payload: token.item ? token.item : token
            });
        }
        dispatch({ 
            type: 'TOGGLE_MODAL', 
            payload: !state.is_modal_on
        });
    } 

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={toggleModal}
                fullWidth={true}
                maxWidth={'sm'}
                className="swap-modal"
            >
                <DialogTitle id="token-selector-header">
                    Select a token
                </DialogTitle>
                <Divider/>
                <DialogContent className="px-0" style={{height:'400px'}}>
                    <div className="row justify-content-center mx-0 mb-3">
                        <div className="col-md-8 col-12">
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                value={keyword}
                                placeholder='Search token name or contract address'
                                onChange={(e) => search(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>  
                    </div>

                    {
                        loading && <div className="d-flex justify-content-center"><CircularProgress/></div>
                    }

                    {
                        (!loading && result.length === 0) && <div className="text-center"><Typography variant="caption">Nothing found</Typography></div>
                    }

                    {
                        tokens.length > 0 && (
                            <Virtuoso
                                ListContainer={ListContainer}
                                style={{height: 'calc(100% - 56px)'}}
                                totalCount={result.length}
                                item={i => {
                                    return (
                                    <>
                                        <ListItem
                                            button
                                            onClick={() => selectToken(result[i])}
                                            selected={
                                                selected_source === (result[i].address || result[i]["item"].address)
                                                ||
                                                selected_destination === (result[i].address || result[i]["item"].address)
                                            }
                                            >
                                            {
                                                result[i]["item"] && (
                                                    <>
                                                    <ListItemAvatar>
                                                        <Avatar 
                                                            alt={result[i]["item"].symbol} 
                                                            src={result[i]["item"].iconUrl}
                                                            className="modal-token-icon-avatar"
                                                        />
                                                    </ListItemAvatar>   
                                                    <ListItemText primary={result[i]["item"].name + ' ' + result[i]["item"].symbol}/>
                                                    <ListItemSecondaryAction>
                                                        {
                                                            selected_source === result[i]["item"].address && (
                                                                <Chip color="primary" size="small" label="From" />
                                                            )
                                                        }
                                                        {
                                                            selected_destination === result[i]["item"].address && (
                                                                <Chip color="secondary" size="small" label="To" />
                                                            )
                                                        }
                                                    </ListItemSecondaryAction>
                                                    </>
                                                )
                                            }
                                            {
                                                result[i]["address"] && (
                                                    <>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={result[i].symbol}
                                                            src={result[i].iconUrl}
                                                            className="modal-token-icon-avatar"
                                                        />
                                                    </ListItemAvatar>   
                                                    <ListItemText primary={result[i].name + ' ' + result[i].symbol}/>
                                                    <ListItemSecondaryAction>
                                                        {
                                                            selected_source === result[i].address && (
                                                                <Chip color="primary" size="small" label="From" />
                                                            )
                                                        }
                                                        {
                                                            selected_destination === result[i].address && (
                                                                <Chip color="secondary" size="small" label="To" />
                                                            )
                                                        }
                                                    </ListItemSecondaryAction>
                                                    </>
                                                )
                                            }
                                        </ListItem>
                                        <Divider/>
                                    </>
                                    );
                                }}
                            />
                        )
                    }
                    
                </DialogContent>

                <DialogActions>
                    <Button onClick={toggleModal} color="primary" variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            
        </Fragment>
    )
}

export default TokenSelectorModal;