import React, {useState} from "react";
import { Typography, Button, Divider } from '@material-ui/core';
import './../../sass/Footer.scss';
import logo from './../../assets/images/logo-alt.png';
import presentation from './../../assets/Feb_2021_Flurbo_Deck.pdf';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {  Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import facebook from './../../assets/icons/facebook.svg';
import twitter from './../../assets/icons/twitter.svg';
import instagram from './../../assets/icons/instagram.svg';
import tiktok from './../../assets/icons/tiktok.svg';

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(255,255,255,0.5)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(255,255,255,0.7)"
        },
        "& .MuiOutlinedInput-input": {
            color: "#fff"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "rgba(255,255,255,0.8)"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#fff"
        },
        "& .MuiInputLabel-outlined": {
            color: "#fff"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#fff"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#fff"
        }
    }
});


const CustomForm = ({ status, message, onValidated }) => {

    const [ email, setEmail ] = useState('');
    const classes = useStyles();
    
    const handleSubmit = (e) => {
        e.preventDefault()
        onValidated({
            EMAIL: email,
        });
        setEmail('');
        alert("Thank you! You have successfully subscribed to our newsletter.")
    }

    return (
        <form onSubmit={handleSubmit} className="d-flex align-items-center">
            <TextField
                type="email"
                label="E-mail"
                variant="outlined"
                className={classes.root}
                value={email}
                fullWidth={true}
                size="small"
                required={true}
                onChange={(event) => setEmail(event.target.value)}
            />
            <Button 
                className="ml-3"
                style={{height:'40px'}}
                color="primary"
                type="submit" 
                variant="contained">
                Submit
            </Button>
        </form>
    )

}

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-6">
                        <Typography variant="h5" className="header">
                            Company
                        </Typography>
                        <ul>
                            <li>{/*<Link to={presentation} target="_blank">Presentation</Link>*/}</li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-6">
                        <Typography variant="h5" className="header">
                            Quick Links
                        </Typography>
                        <ul>
                            <li><Link to="/swap">Swap</Link></li>
                            {/*<li><Link to="/">Staking</Link></li>*/}
                        </ul>
                    </div>
                    <div className="col-md-4 col-12 mt-4 mt-md-0">
                        <Typography variant="h5" className="header">
                            Newsletter
                        </Typography>
                        <Typography variant="body2" className="mb-2">
                            Subscribe to recieve email updates.
                        </Typography>
                        <MailchimpSubscribe
                            url={"//flurbo.us1.list-manage.com/subscribe/post?u=dd44cd897074f9847d1fd163a&id=d07142e5a3"}
                            render={({ subscribe, status, message }) => (
                                <CustomForm
                                    status={status}
                                    message={message}
                                    onValidated={formData => subscribe(formData)}
                                />
                            )}
                        />
                    </div>
                </div> 
                <Divider className="divider"/>
                <div className="row align-items-center">
                    <div className="col-md-4 col-12 text-md-left text-center">
                        <Typography variant="body2" className="legal mb-2">
                            All rights reserved &copy; {new Date().getFullYear()}
                        </Typography>
                        <Typography variant="body2" className="legal">
                            <strong>Nifty Technologies Inc.</strong>. &mdash; <a href="mailto:info@flurbo.com">Contact</a>
                        </Typography>
                    </div>
                    <div className="col-md-4 col-12 text-center my-5 my-md-0">
                        <img src={logo} className="logo-image"/>
                    </div>

                    <div className="col-md-4 col-12 social-media-holder text-md-right text-center">

                        <a href="https://www.facebook.com/niftycentral" target="_blank" className="mr-3">
                            <img src={facebook} className="facebook grow"/>
                        </a>
                        <a href="https://twitter.com/niftycentral" target="_blank" className="mr-3">
                            <img src={twitter} className="twitter grow"/>
                        </a>
                        <a href="https://www.instagram.com/niftycentral/" target="_blank" className="mr-3">
                            <img src={instagram} className="linkedin grow"/>
                        </a>
                        <a href="https://www.tiktok.com/@niftycentral/" target="_blank" className="mr-3">
                            <img src={tiktok} className="linkedin grow"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
