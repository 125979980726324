import React, { Fragment, useState, useEffect, useContext } from "react";
import { AppContext } from './context';
import { swap } from './../../helpers/handleTotle';
import { useSnackbar } from 'notistack';
import hash from 'object-hash';
import TransactionLog from './TransactionLog';
import TransactionPath from './TransactionPath';
import { 
    Divider, 
    Button, 
    Typography
} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';

let object_hash;

const buildObjectCache = (state) => {
    return hash({
        ...state.destination_amount,
        ...state.source_amount,
        ...state.destination,
        ...state.source
    });
}

const TransferModule = () => {

    // state
    const [reloading, setReloading] = useState(false);
    const [values,setValues] = useState({
        ready: false,
        rate: '',
        guaranteedRate: '',
        amount: '',
        path: []
    });
    const [soft_error, setSoftError] = useState(false);
    const [soft_error_text, setSoftErrorText] = useState('');

    // context
    const {state, dispatch} = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    // lifecycle
    useEffect(() => {
        console.log("State of TransferModule", state)
        
        if(state.source_amount && state.source_amount > 0) {

            if(!state.source || !state.destination || !state.web3 ) return;
            
            if( (buildObjectCache !== object_hash && state.destination_amount) || !state.destination_amount) {
                // call because hash is changed
                dispatch({ type: 'TOGGLE_LOADING', payload: true});
                handleSwap(
                    state.source.symbol,
                    state.destination.symbol,
                    state.source_amount,
                    state.address
                )
            }  
        }

    },[state.source_amount,state.source,state.destination]);

    // methods
    const handleSwap = async (sourceAsset, destinationAsset, sourceAmount, address) => {

        // test if amount is convertable
        let wei_amount;
        try {
            wei_amount = state.web3.utils.toWei(sourceAmount);
        } catch(err) {
            return;
        }

        const [error,response] = await swap(sourceAsset, destinationAsset, wei_amount, address);
        setReloading(false);

        if(error) {
            console.log("Error", error)
            dispatch({ type: 'TOGGLE_LOADING', payload: false});
            enqueueSnackbar('Error! Something went wrong, please try again later.', { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            return;
        }
        if(!response.data.success) {
            console.log("soft error",response.data);
            setSoftError(true);
            setValues({
                ...values,
                ready: false
            });
            if(response.data && response.data.response && response.data.response.message) {
                setSoftErrorText(response.data.response.message);
            } else {
                setSoftErrorText("Something went wrong.");
            }
            dispatch({ type: 'TOGGLE_LOADING', payload: false});
            object_hash = buildObjectCache(state);
            return;
        }
        console.log("Success", response.data);
        
        let rate = response.data.response.summary[0]["rate"],
            guaranteedRate = response.data.response.summary[0]["guaranteedRate"],
            amount = rate * state.source_amount,
            amount_guaranteed = guaranteedRate * state.source_amount,
            path = response.data.response.summary[0]["path"],
            transaction = response.data.response.transactions ? response.data.response.transactions[0] : null;

        dispatch({ type: 'SET_AMOUNT', payload: {
            'source_amount': state.source_amount,
            'destination_amount': amount
        }});

        dispatch({ type: 'TOGGLE_LOADING', payload: false});
        object_hash = buildObjectCache(state);

        setSoftError(false);
        setValues({
            rate: rate,
            guaranteedRate: guaranteedRate,
            amount: amount,
            amount_guaranteed: amount_guaranteed,
            ready: true,
            destination_symbol: state.destination.symbol,
            source_symbol: state.source.symbol,
            path: path,
            transaction: transaction
        });

    }
    
    const reloadRate = () => {
        if(state.source_amount && state.source_amount > 0 && state.source && state.destination && state.web3) {
            setReloading(true);
            dispatch({ type: 'TOGGLE_LOADING', payload: true});
            handleSwap(
                state.source.symbol,
                state.destination.symbol,
                state.source_amount,
                state.address
            )
        }
    }

    const handleTransaction = async () => {

        let gasPrice = await state.web3.eth.getGasPrice();
        let tx = values.transaction.tx;

        tx.gasPrice = gasPrice;
        let confirmation_number = 0;

        state.web3.eth.sendTransaction(tx)
            .once('transactionHash', (transactionHash) => {
                dispatch({ 
                    type: 'SET_TRANSACTION', 
                    payload: {
                        transactionHash: transactionHash,
                        started: true,
                        completed: false,
                        confirmation: confirmation_number,
                        receipt: null
                    }
                });
            })
            .on('confirmation', (confNumber, receipt) => {
                confirmation_number = confNumber;
                dispatch({ 
                    type: 'SET_TRANSACTION', 
                    payload: {
                        transactionHash: receipt.transactionHash,
                        started: true,
                        completed: false,
                        confirmation: confirmation_number,
                        receipt: receipt
                    }
                });
            })
            .on('error', (error) => {

                console.log("error", error);

                let error_message = 'Error! Something went wrong.'
                if(error.code) {
                    error_message = error_message + ' Code: ' + error.code
                }
                enqueueSnackbar(error_message, { 
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });

            }).then(receipt => {
                console.log("Completed", receipt)
                dispatch({ 
                    type: 'SET_TRANSACTION', 
                    payload: {
                        transactionHash: receipt.transactionHash,
                        started: false,
                        completed: true,
                        receipt: receipt,
                        confirmation: confirmation_number
                    }
                });
            });
    }

    return (
        <Fragment>
            <div className="transfer-module">
                    
                {
                    soft_error && (
                        <Alert severity="warning" className="my-3">
                            <AlertTitle>Attention!</AlertTitle>
                            {soft_error_text}
                        </Alert>
                    )
                }
                {
                    values.ready && (
                        <div className="summary">
                            {
                                state.transaction && (state.transaction.started || state.transaction.completed) && state.transaction.transactionHash && <TransactionLog/>
                            }
                            <Typography variant="h5">
                                Rate
                            </Typography>
                            <div className="row mt-2">
                                <div className="col-md-4 col-4">
                                    <Typography variant="body1" className="key">
                                        Recent:
                                    </Typography>
                                </div>

                                <div className="col-md-8 col-8 text-right text-md-left">
                                    <Typography variant="body1" className="value">
                                        1 {values.source_symbol} = {parseFloat(values.rate).toFixed(4)} {values.destination_symbol}
                                    </Typography>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <Typography variant="body1" className="key">
                                        Guaranteed:
                                    </Typography>
                                </div>
                                <div className="col-md-8 col-8 text-right text-md-left">
                                    <Typography variant="body1" className="value">
                                        1 {values.source_symbol} = {parseFloat(values.guaranteedRate).toFixed(4)} {values.destination_symbol}
                                    </Typography>
                                </div>
                            </div>
                            <Divider className="my-3"/>
                            <Typography variant="h5">
                                Amount
                            </Typography>
                            <div className="row mt-2">
                                <div className="col-md-4 col-4">
                                    <Typography variant="body1" className="key">
                                        Total:
                                    </Typography>
                                </div>
                                <div className="col-md-8 col-8 text-right text-md-left">
                                    <Typography variant="body1" className="value">
                                        {parseFloat(values.amount_guaranteed).toFixed(4)} - {parseFloat(values.amount).toFixed(4)} {state.destination.symbol}
                                    </Typography>
                                </div>
                            </div>
                            
                            <Divider className="my-3"/>
                            <Typography variant="h5">
                                Transaction Path
                            </Typography>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <TransactionPath path={values.path}/>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="text-center">
                    {
                        (values.ready && values.transaction) && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleTransaction}
                                className="my-3 d-block w-100"
                            >
                                Swap!
                            </Button>
                        ) 
                    }
                </div>
            </div>

        </Fragment>
    )
}

export default TransferModule;