import React from "react";
import { Typography } from '@material-ui/core';
import './../../sass/Featurette.scss';
import illustration_1 from './../../assets/images/chart.png';
import illustration_2 from './../../assets/images/calamity.png';
import illustration_3 from './../../assets/images/blocks.png';

const Featurette = () => {
    return (
        <div className="container featurette">
            <div className="row align-items-center" style={{marginBottom:'120px'}}>
                <div className="col-md-6 pr-md-5 mb-4 mb-md-0">
                    <Typography variant="caption" className="caption">
                        01
                    </Typography>
                    <Typography variant="h3">
                        Full-Service Staking<div>Infrastructure</div>
                    </Typography>
                    <Typography variant="body1">
                    Flurbo offers a "Stake and Take" model with managed server hardware. Simply "Stake" your crypto, and "Take" your earned yield.
                    </Typography>
                </div>
                <div className="col-md-6">
                    <img src={illustration_1} className="img-fluid"/>
                </div>
            </div>
            <div className="row align-items-center mt-5" style={{marginBottom:'120px'}}>
                <div className="col-md-6 px-md-5 order-1 order-md-0">
                    <img src={illustration_2} className="img-fluid"/>
                </div>
                <div className="col-md-6 order-0 order-md-1 mb-4 mb-md-0">
                    <Typography variant="caption" className="caption">
                        02
                    </Typography>
                    <Typography variant="h3">
                        Keep Calm and Retain<div>Custody</div>
                    </Typography>
                    <Typography variant="body1">
                        Peace of mind is much more valuable than any rate of return. Flurbo is completely non-custodial, keeping you in full control of your crypto assets.
                    </Typography>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-md-6 pr-md-5 mb-4 mb-md-0">
                    <Typography variant="caption" className="caption">
                        03
                    </Typography>
                    <Typography variant="h3">
                        Best DeFi? Just <div>"Flurbo It"!</div>
                    </Typography>
                    <Typography variant="body1">
                        Do you prefer Google Flights over searching with individual airlines? You are not alone. Flurbo takes a similar "optimal path to end goal" approach to identifying DeFi opportunities.
                    </Typography>
                </div>
                <div className="col-md-6">
                    <img src={illustration_3} className="img-fluid"/>
                </div>
            </div>
        </div>
     
    )
}

export default Featurette;
