import uniswap from './../assets/images/platforms/uniswap.svg';
import kyber from './../assets/images/platforms/kyber.png';
import bancor from './../assets/images/platforms/bancor.svg';
import fulcrum from './../assets/images/platforms/fulcrum.png';
import airswap from './../assets/images/platforms/airswap.svg';
import zx from './../assets/images/platforms/zx.svg';
import compound from './../assets/images/platforms/compound.svg';
import chai from './../assets/images/platforms/chai.png';
import synthetix from './../assets/images/assets/snx.png';
import aave from './../assets/images/platforms/aave.jpg';
import set from './../assets/images/platforms/set.png';
import idle from './../assets/images/platforms/idle.png';

const platforms = [{
    name: 'Kyber Network',
    icon: kyber,
    class: 'kyber'
}, {
    name: 'Uniswap',
    icon: uniswap,
    class: 'uniswap'
},{
    name: 'Bancor',
    icon: bancor,
    class: 'bancor'
}, {
    name: 'Fulcrum',
    icon: fulcrum,
    class: 'fulcrum'
}, {
    name: 'AirSwap',
    icon: airswap,
    class: 'airswap'
}, {
    name: '0x Protocol',
    icon: zx,
    class: 'zx'
},{
    name: 'Compound',
    icon: compound,
    class: 'compound'
},{
    name: 'Chai',
    icon: chai,
    class: 'chai'
},{
    name: 'Synthetix',
    icon: synthetix,
    class: 'synthetix'
},{
    name: 'TokenSets',
    icon: set,
    class: 'set'
}, {
    name: 'Aave',
    icon: aave,
    class: 'aave'
},{
    name: 'Idle',
    icon: idle,
    class: 'idle'
}]

export default platforms;