import React, { Fragment } from "react";
import { Typography, Avatar } from '@material-ui/core';
import './../../sass/Platforms.scss';
import platforms from './../../local_data/platforms.js';

const Platforms = () => {

    return (
        <Fragment>
            <div className="container platforms">
                <div className="row">
                    <div className="col-12 heading text-center">
                        <Typography variant="h3">
                            Platforms we support
                        </Typography>
                        <Typography variant="body1">
                            The most trusted industry leading platforms.
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="container platforms horizontal-wrapper">
                <div className="row list flex-nowrap">
                    {
                        platforms.map((platform) =>
                            <div className="col-md-3 col-5 platform text-center" key={Math.random()}>
                                <div className="d-flex justify-content-center">
                                    <Avatar 
                                        className={platform.class ? platform.class + ' avatar' : 'avatar' }
                                        src={platform.icon}
                                    />
                                </div>
                                <Typography variant="h4">
                                    {platform.name}
                                </Typography>
                            </div>
                        )
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default Platforms;
