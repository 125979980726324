import axios from "axios";
import config from "../config";

/**
 * Reset password
 */
export const resetPassword = async (code,password) => {
  try {
    let response = await axios({
      method: "post",
      url: `${config.SERVER_URL}/auth/reset-password`,
      data: {
        code: code,
        password: password,
        passwordConfirmation: password,
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};
