import React from "react";
import { Typography } from '@material-ui/core';
import illustration from './../../assets/images/staking-hero.png';
import './../../sass/Hero.scss';

const Hero = () => {
    return (
        <div className="container-fluid">
            <div className="row hero">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-6 col-12">
                            <Typography variant="h2">
                                Removing the "Technical Mumbo-Jumbo" from DeFi and Crypto.
                            </Typography>
                            <Typography variant="body1" className="subheader">
                            Flurbo offers simplified non-custodial solutions for crypto assets, including a DeFi recommendation engine and managed infrastructure for staking.
                            </Typography>
                        </div>
                        <div className="col-md-6 col-12">
                            <img src={illustration}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;
