import React, { Fragment } from "react";
import { Route, NavLink, Redirect } from "react-router-dom";
import Security from './Security';
import Profile from './Profile';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useSelector } from 'react-redux';

const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
));

export default function Settings(props) {
    
    // redux
    const app = useSelector(state => state.app);

    if(!app.loggedin) {
        return <Redirect to="/"/>
    }

    return (
        <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-12 mb-3 mb-md-0">
                        <Paper variant="outlined">
                            <List>

                                <ListItem 
                                    activeClassName="Mui-selected"
                                    button
                                    component={ForwardNavLink}
                                    to={"/settings/profile"}
                                    >
                                    <ListItemIcon>
                                        <AccountCircleIcon style={{opacity: 0.6}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Profile" />
                                </ListItem>

                                <ListItem 
                                    activeClassName="Mui-selected"
                                    button
                                    component={ForwardNavLink}
                                    to={"/settings/security"}
                                    >
                                    <ListItemIcon>
                                        <LockIcon style={{opacity: 0.6}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Security" />
                                </ListItem>
                            </List>
                        </Paper>
                    </div>
                    <div className="col-md-9 col-12">
                        <Route exact path={'/settings/profile'} render={(props) => <Profile {...props} />} />
                        <Route exact path={'/settings/security'} render={(props) => <Security {...props} />} />
                    </div>
                </div>
            </div>
            
        </Fragment>
        
    )

}
