import React, { createContext } from "react";

// Create context object

export const AppContext = createContext();

// Set up Initial State
export const initialState = {
    is_modal_on: false,
    loading: false,
    source: null,
    destination: null,
    select: null,
    source_amount: '',
    destination_amount: '',
    tokens: [],
    transaction: {
        started: false,
        completed: false,
        confirmation: 0,
        receipt: null,
    },
    address: '',
    web3: null,
    metamask: null
};

// Context reducer
export const reducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_MODAL':
            return {
                ...state,
                is_modal_on: action.payload
            };
        case 'SET_SOURCE':
            return {
                ...state,
                source: action.payload
            };
        case 'SET_DESTINATION':
            return {
                ...state,
                destination: action.payload
            };
        case 'SET_SELECT':
            return {
                ...state,
                select: action.payload
            };
        case 'SET_AMOUNT':
            return {
                ...state,
                source_amount: action.payload.source_amount,
                destination_amount: action.payload.destination_amount
            };
        case 'TOGGLE_LOADING':
            return {
                ...state,
                loading: action.payload
            };
        case 'SAVE_TOKENS':
            return {
                ...state,
                tokens: action.payload
            };
        case 'SET_TRANSACTION':
            return {
                ...state,
                transaction: {
                    started: action.payload.started,
                    completed: action.payload.completed,
                    transactionHash: action.payload.transactionHash,
                    confirmation: action.payload.confirmation,
                    receipt: action.payload.receipt
                }
            };
        case 'SET_METAMASK':
            return {
                ...state,
                metamask: action.payload
            };
        case 'SET_WEB3':
            return {
                ...state,
                web3: action.payload
            };
        case 'SET_ADDRESS':
            return {
                ...state,
                address: action.payload
            };
        default:
            return initialState;
    }
}
