import axios from "axios";
import config from "../config";

/**
 * Get current user
 */
export const getCurrentUser = async (token) => {
  try {
    let response = await axios({
      method: "get",
      url: `${config.SERVER_URL}/users/me`,
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};