import axios from "axios";
import config from "../config";

/**
 * Forgot password
 */
export const forgotPassword = async (email) => {
  try {
    let response = await axios({
      method: "post",
      url: `${config.SERVER_URL}/auth/forgot-password`,
      data: {
        email: email
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};
