import axios from "axios";
import config from "../config";

/**
 * Signup
 */
export const signUp = async (username,email,password) => {
  try {
    let response = await axios({
      method: "post",
      url: `${config.SERVER_URL}/auth/local/register`,
      data: {
        username: username,
        email: email,
        password: password
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};
