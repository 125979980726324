import Web3 from "web3";

export const getMetamask = (windowObject) => {

	if(!windowObject.web3) {
		return false
	}
	return windowObject.web3;

}

export const getWeb3 = () => {
	return Web3;
}

export const connectToMetamask = async(windowObject) => {

	if(!windowObject || !windowObject.ethereum) {
		throw new Error("Window object or Metamask not found!")
	}

	try {
		let enable = await windowObject.ethereum.enable()
		return enable;
	} catch(err) {
		if(err && err.code === 4001) {
			throw new Error(err.message)
		}
		return err;
	}

}

export const getAddress = async(web3) => {

	try {
		const address = await web3.eth.getAccounts();
		return address
	} catch(err) {
		throw new Error("Could not get users address.")
	}

}

export const stringShortener = (string,limit) => {

	let first_portion = string.substring(0, limit);
	let last_portion = string.substring(string.length - limit);

	return first_portion + "..." + last_portion;

}