import React, { Fragment, useReducer, useEffect } from "react";
import TokenSelectorModal from './TokenSelectorModal';
import TransferModule from './TransferModule';
import Widget from './Widget';
import SwapHeader from './SwapHeader';
import { AppContext, reducer } from './context';
import './../../sass/Swap.scss';

const Swap = () => {

    //context
    const [state, dispatch] = useReducer(reducer, {
        is_modal_on: false,
        loading: false,
        source: {
            address: "0x0000000000000000000000000000000000000000",
            color: "#122333",
            decimals: 18,
            iconUrl: "https://s3.amazonaws.com/totle-token-icons/ETH-icon.png",
            name: "Ether",
            swappable: true,
            symbol: "ETH"
        }
    });

    return (
        <Fragment>
            <AppContext.Provider value={{ state, dispatch }}>
                <SwapHeader/>
                <TokenSelectorModal/>
                <div className="container swap-widget-alt">
                    <div className="row h-100 justify-content-md-center align-items-md-center">
                        <div className="col-md-8 col-lg-5 col-12">
                            <Widget/>
                            <TransferModule/>
                        </div>
                    </div>
                </div>
            </AppContext.Provider>
        </Fragment>
    )
}

export default Swap;