import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import deepPurple from '@material-ui/core/colors/deepPurple';

const dark_theme = createMuiTheme({
  	palette: {
        //type: 'dark',
    	primary: indigo,
      	secondary: deepPurple,
        background: {
           // default: '#121212'
        }
  	},
    //shadows: ["none"],
  	shape: {
        borderRadius: 4,
    },
    typography: {
	    button: {
	      	fontWeight: '600',
	      	textTransform: 'none'
        },
        caption: {
            letterSpacing: 0,
            opacity:0.7
        },
        fontFamily: '"Open Sans", "Helvetica", sans-serif',
        letterSpacing: -0.5,
        h1: {
            fontFamily: '"Poppins", "Helvetica", sans-serif',
            letterSpacing: '-0.5px',
        },
        h2: {
            fontFamily: '"Poppins", "Helvetica", sans-serif',
            letterSpacing: '-0.5px'
        },
        h3: {
            fontFamily: '"Poppins", "Helvetica", sans-serif',
            letterSpacing: '-0.5px'
        },
        h4: {
            fontFamily: '"Poppins", "Helvetica", sans-serif',
            letterSpacing: '-0.5px'
        },
        h5: {
            fontFamily: '"Poppins", "Helvetica", sans-serif',
            letterSpacing: '-0.5px',
            fontWeight: 600
        },
        h6: {
            fontFamily: '"Poppins", "Helvetica", sans-serif',
            letterSpacing: '-0.5px'
        }

        
	},
    overrides: {

        MuiPaper: {
            root: {
                //backgroundColor: '#252525',
                //color: '#fff !important'
            }
        },
        MuiAlert: {
            message: {
                display: 'inline'
            },
            standardError: {
                backgroundColor: '#5f2f2c',
            }
        },
        MuiAlertTitle: {
            root: {
                fontWeight: 700
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: '38px'
            }
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: '#ffffff',
                color: '#70a092',
                border: '2px solid #79b9a6'
            },
            rounded: {
                borderRadius: '10px',
                backgroundColor: '#ffffff',
                border: '2px solid #79b9a6'
            }
        },
        MuiDialogTitle: {
            root: {
                padding: '10px 15px 10px 15px'
            }
        },
        MuiDialogContent: {
            root: {
                padding: '15px 15px 0 15px'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '15px 15px 15px 15px'
            }
        },
        MuiTab: {
            root: {
                minWidth: 'auto !important',
                padding: '0 15px 0 15px'
            }
        }
    }
});

export default dark_theme;

