import React from 'react';
import TextField from '@material-ui/core/TextField';
import { ValidatorComponent } from 'react-form-validator-core';

export default class SelectValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        return (
            <TextField
                {...rest}
                select
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
            />
        );
    }
}

/*
export default class SelectValidator extends ValidatorComponent {

    render() {
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        return (
            <FormControl className="w-100">
                <TextField
                    variant="outlined"
                    {...rest}
                    select
                    error={!isValid || error}
                    helperText={(!isValid && this.getErrorMessage()) || helperText}
                />
            </FormControl>
        );
    }
}
*/