import React, { useEffect, useState, Fragment } from "react";
import Navigation from './components/Navigation';
import { getCurrentUser } from './helpers/getCurrentUser';
import { useSelector, useDispatch } from 'react-redux';
import { RestoreUser } from './store/actions/app.js';
import { withRouter } from 'react-router-dom';

const Bootstrap = (props) => {

    // state
    const [loading,setLoading] = useState(true);

    // redux
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();
    
    useEffect(() => {

        // check if auth token was saved in localStorage
        const token = localStorage.getItem("token");

        if(token && app.loggedin) {
            // loggedin just now
            setLoading(false);
            return;
        }

        if(!token && !app.loggedin) {
            // not loggedin at all
            setLoading(false);
            return;
        }

        if(token && !app.loggedin) {
            // were loggedin at some point
            // try restore user object in redux
            auth(token);
        }

    },[]);

    const auth = async (token) => {
        const [error,response] = await getCurrentUser(token);
        if(error) {
            // token was busted. Clean up everything
            localStorage.removeItem("token");
            setLoading(false);
            return;
        }
        dispatch( RestoreUser(response.data, token) );
        setLoading(false);
    }
    return (
        <Fragment>
            {
                loading ? (
                    <div></div>
                ) : (
                    <Fragment>
                        <Navigation {...props}/>
                        {props.children}
                    </Fragment>
                )
            }
        </Fragment>
    )

}

export default withRouter(Bootstrap);
