import React, { Fragment } from "react";
import { 
    Chip
} from "@material-ui/core";

const TransactionPath = ({path}) => {
    return (
        <div className="mb-3">
            {
                path.map((stop,i) =>
                    <Fragment key={Math.random()}>
                        <Chip label={stop.symbol} variant="default" color="primary" size="small"/>

                        {
                            path.length - 1 == i ? null : <span className="mx-1">&rarr;</span>
                        }
                        
                    </Fragment>
                )
            }
        </div>
    )
}


export default TransactionPath;