import axios from "axios";
import config from "../config";

/**
 * Swap
 */
export const swap = async (sourceAsset,destinationAsset,sourceAmount,address) => {
  let query = {
    method: "post",
    url: `${config.SERVER_URL}/tokens/rate`,
    data: {
      sourceAsset: sourceAsset, 
      destinationAsset: destinationAsset,
      sourceAmount: sourceAmount,
      address: address
    }
  };
  try {
    let response = await axios(query);
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const getTokens = async () => {
  let query = {
    method: "get",
    url: `${config.SERVER_URL}/tokens/swappable`
  };
  try {
    let response = await axios(query);
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const dynamicSort = (property) => {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
};
