import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import Featurette from './components/Splash/Featurette';
import About from './components/Splash/About';
import Assets from './components/Splash/Assets';
import Platforms from './components/Splash/Platforms';
import Footer from './components/Splash/Footer';
import Team from './components/Splash/Team';

export default function Home(props) {

    // redux
    const app = useSelector(state => state.app);
    
    return (
        <Fragment>
            
            <About {...props}/>
            <Featurette {...props}/>
            <Assets {...props}/>
            <Platforms {...props}/>
            {/*<Team {...props}/>*/}
            <Footer {...props}/>
        </Fragment>
        
    )

}
