import React, { Fragment, useEffect, useState } from "react";
import assets from './local_data/assets.js';
import { Typography, 
    Button, 
    Avatar,
    Paper,
    Divider
} from '@material-ui/core';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import SelectValidator from './components/forms/Selector';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import './sass/Stake.scss';

const InvestorCategories = [
    {
      "name": "Individual Investor",
      "slug": "individual-investor"
    },
    {
      "name": "Crypto or Venture Fund",
      "slug": "crypto-or-venture-fund"
    },
    {
      "name": "Exchange",
      "slug": "exchange"
    },
    {
      "name": "Other",
      "slug": "other"
    }
]

const AssetsValue = [
    {
      "name": "$10 Million +",
      "slug": "10+"
    },
    {
      "name": "$1 Million -$10 Million",
      "slug": "1-10"
    },
    {
      "name": "$250k - $1 Million",
      "slug": "0.25-1"
    },
    {
      "name": "$10k - $250k",
      "slug": "0.1-0.25"
    },
    {
        "name": "< $10k",
        "slug": "<0.1"
      }
]

export default function Stake(props) {

    // state
    const [token, setToken] = useState(null);
    const [submitting, setSubmittimg] = useState(false)
    const [values, setValues] = useState({
        firstname: '', 
        lastname: '', 
        company: '', 
        email: '',
        category: '',
        assets: ''
    });

    useEffect(() => {
        let find_token = assets.find(token => token.symbol === props.match.params.symbol);
        if(!find_token) return;
        setToken(find_token);
    },[])

    if(!token) return null;

    // methods
    const handleSubmit = () => {

    }

    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }
    
    return (
        <Fragment>
            <div className="stake-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-12 d-flex">
                            <Avatar 
                                className={token.class ? token.class + ' avatar' : 'avatar'}
                                src={token.icon}
                            />
                            <div className="ml-md-5 ml-3">
                                <Typography variant="h2">{token.name}</Typography>
                                <Typography variant="body1">{token.description}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container stake">
                <div className="row">
                    <div className="col-md-8 col-12 mb-3">
                        <Paper className="kickstart-form p-md-5" elevation={3}>

                            <div className="heading">
                                <Typography variant="h3">
                                    Get started now
                                </Typography>
                                <Typography variant="body1">
                                    Create your custom-tailored staking plan.
                                </Typography>
                            </div>
                            <Divider className="mt-3"/>
                            <div className="cta">
                                <Typography variant="h4">
                                    Why Delegate to Flurbo? 
                                </Typography>
                                <ul>
                                    <li><strong>Proven Partner</strong>: We're the staking partner of choice for 40+ crypto funds and thousands of investors;</li>
                                    <li><strong>Reliability</strong>: Our 100% uptime SLA ensures you get paid reliably and securely;</li>
                                    <li><strong>Non-custodial</strong>: We do not custody your assets, so there's no risk your funds are stolen.</li>
                                </ul>
                            </div>
                            
                            <ValidatorForm
                                onSubmit={handleSubmit}
                                noValidate
                            >
                                <TextValidator
                                    name="firstname"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstname"
                                    label="First Name"
                                    className="mb-3"
                                    autoFocus
                                    value={values.firstname}
                                    onChange={(event) => handleInputChange(event,'firstname')}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                />

                                <TextValidator
                                    name="lastname"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastname"
                                    label="Last Name"
                                    className="mb-3"
                                    value={values.lastname}
                                    onChange={(event) => handleInputChange(event,'lastname')}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                />

                                <TextValidator
                                    name="email"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="E-mail"
                                    className="mb-3"
                                    value={values.email}
                                    onChange={(event) => handleInputChange(event,'email')}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['This field is required', 'Please enter a valid e-mail address']}
                                />

                                <SelectValidator
                                    label="Category"
                                    name="category"
                                    id="category"
                                    className="mb-3"
                                    onChange={(event) => handleInputChange(event,'category')}
                                    value={values.category}
                                    input={<OutlinedInput name="category" id="category" />}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    fullWidth={true}
                                    variant="outlined"
                                    helperText="Which best describes you?"
                                >
                                    { 
                                        InvestorCategories.map(
                                            (category) =>
                                                <MenuItem key={category.slug} value={category.slug}>
                                                    {category.name}
                                                </MenuItem>
                                            ) 
                                    }
                                </SelectValidator>
                                
                                <SelectValidator
                                    label="Total Assets Value"
                                    name="assets"
                                    id="assets"
                                    onChange={(event) => handleInputChange(event,'assets')}
                                    value={values.assets}
                                    input={<OutlinedInput name="assets" id="assets" />}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    fullWidth={true}
                                    variant="outlined"

                                >
                                    { 
                                        AssetsValue.map(
                                            (asset) =>
                                                <MenuItem key={asset.slug} value={asset.slug}>
                                                    {asset.name}
                                                </MenuItem>
                                            ) 
                                    }
                                </SelectValidator>



                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div>
                                        <Button 
                                            disabled={submitting}
                                            size="large" 
                                            variant="contained" 
                                            color="primary" 
                                            type="submit">Submit</Button>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </Paper>
                    </div>
                    <div className="col-md-4 col-12 mt-4 dyi">
                        <div className="heading">
                            <Typography variant="h4">
                                Do it yourself
                            </Typography>
                            <Typography variant="body2">
                                Follow the instructions bellow to get started.
                            </Typography>
                        </div>
                        <Divider className="mt-3 mb-3"/>
                        <ul className="steps">
                            <li className="step">
                                Install the CLI and run the following commands:
                                <ul className="substeps">
                                    <li className="substep">
                                        <strong>Command 1</strong> - Locks Celo Gold (cGLD) to be used in governance and validator elections.
                                    </li>
                                    <li className="substep">
                                        <code>lock --from 0x47e172F6CfB6c7D01C1574fa3E2Be7CC73269D95 --value 10000000000000000000000</code>
                                    </li>
                                    <li className="substep">
                                        <strong>Command 2</strong> - Vote for a Validator Group in validator elections.
                                    </li>
                                    <li className="substep">
                                        <code>vote --from 0x4443d0349e8b3075cba511a0a87796597602a0f1 --for 0x8a12caB622B8093208931fA008D12D6Ba5AF47E4, --value 10000000000000000000000</code>
                                    </li>
                                    <li className="substep">
                                        <strong>Command 3</strong> - To earn rewards as a voter, it is required to activate your pending votes at some point after the end of the epoch in which they were made. This command should be executed at least 24 hours after voting for the validator group. Rewards will not be earned if this command is not executed.
                                    </li>
                                    <li className="substep">
                                        <code>activate --from 0x9E236db32fcE9471A2099059A299956a2c4C61D0</code>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}
