import axios from "axios";
import config from "../config";

/**
 * Login
 */
export const loginUser = async (identifier,password) => {
  try {
    let response = await axios({
      method: "post",
      url: `${config.SERVER_URL}/auth/local`,
      data: {
        identifier: identifier,
        password: password
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};
