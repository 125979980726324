import React, { Fragment, useEffect, useState, useContext } from "react";
import abi from 'human-standard-token-abi';
import { 
    Typography, 
    Button,
    Avatar,
    CircularProgress 
} from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AppContext } from './context';
import { useDebounce } from '../../helpers/useDebounce';

const Widget = () => {

    // state
    const [values, setValues] = useState({
        source_amount: '', 
        destination_amount: ''
    });
    const [balance, setBalance] = useState('');

    //context
    const {state, dispatch} = useContext(AppContext);

    // hooks
    const debouncedSourceAmount = useDebounce(values.source_amount, 1000);

    // methods
    const toggleTokenSelectorModal = (type) => {
        dispatch({ type: 'TOGGLE_MODAL', payload: !state.is_modal_on});
        dispatch({ type: 'SET_SELECT', payload: type});
    } 

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setValues({...values, [name]: value})
    }

    // lifecycle
    useEffect(
        () => {
            if (debouncedSourceAmount) {
                dispatch({ type: 'SET_AMOUNT', payload: {
                    'source_amount': values.source_amount,
                    'destination_amount': state.destination_amount
                }});
            }
        },
        [debouncedSourceAmount]
    );

    useEffect(() => {
        if(state.source && state.web3 && state.address) {
            if(state.source.address === '0x0000000000000000000000000000000000000000') {
                state.web3.eth.getBalance(state.address).then(balance => {
                    let tokenBalance = state.web3.utils.fromWei(balance);
                    setBalance(tokenBalance);
                });
                return;
            }

            let contractAbi = new state.web3.eth.Contract(abi,state.source.address);
            contractAbi.methods.balanceOf(state.address).call().then(function (balance) {
                let tokenBalance = parseInt(balance) / Math.pow(10, state.source.decimals);
                setBalance(tokenBalance);
            });

        }
    },[state.source,state.web3,state.address])

    useEffect(() => {
        if(state.destination_amount) {
            setValues({...values, 'destination_amount': state.destination_amount})
        }
    },[state.destination_amount]);

    useEffect(() => {
        console.log("state.loading",state.loading)
    },[state.loading]);

    return (
        
        <div className="widget">
            <div className="from">
                <div className="d-flex justify-content-between">
                    <Typography variant="caption" className="label">From</Typography>
                    <Typography variant="caption" className="label">
                        {
                            balance && parseFloat(balance).toFixed(6)
                        }
                    </Typography>
                </div>
                
                <div className="d-flex align-items-center">
                    <Button
                        onClick={() => toggleTokenSelectorModal("source")}
                        color="default"
                        size="small"
                        variant="outlined"
                        className="select-token"
                        endIcon={<ExpandMoreIcon style={{marginLeft: '-5px'}}/>}
                    >
                        {
                            state.source ? (
                                <>
                                    <Avatar 
                                        alt={state.source.symbol}
                                        src={state.source.iconUrl}
                                        className="token-icon-avatar"
                                    />
                                    {state.source.symbol}
                                </>
                            ) : (
                                <><MoreHorizIcon/></>
                            )
                        }
                    </Button>
                    <div className="w-100">
                        <input 
                            type="text" 
                            name="source_amount"
                            placeholder="0.0"
                            className="w-100 amount-input"
                            value={values.source_amount}
                            onChange={(e) => handleInputChange(e,'source_amount')}
                        />
                    </div>
                </div>
            </div>
            <div className="to">
                <Typography variant="caption" className="label">To</Typography>
                <div className="d-flex align-items-center">
                    <Button
                        onClick={() => toggleTokenSelectorModal("destination")}
                        color="default"
                        variant="outlined"
                        className="select-token"
                        endIcon={<ExpandMoreIcon style={{marginLeft: '-5px'}}/>}
                    >
                        
                        {
                            state.destination ? (
                                <>
                                    <Avatar 
                                        alt={state.destination.symbol}
                                        src={state.destination.iconUrl}
                                        className="token-icon-avatar"
                                    />
                                    {state.destination.symbol}
                                </>
                            ) : (
                                <span className="placeholder">Select</span>
                            )
                        }
                    </Button>
                    <div className="w-100 loading-wrapper d-flex align-items-center">
                        {
                            state.loading ? (
                                <CircularProgress color="primary" style={{color:'#fff',width:'22px',height:'22px'}}/>
                            ) : (
                                <input 
                                    type="text" 
                                    name="destination_amount"
                                    placeholder="0.0"
                                    className="w-100 amount-input readonly"
                                    disabled
                                    value={values.destination_amount ? parseFloat(values.destination_amount).toFixed(4) : ''}
                                    //onChange={(e) => handleInputChange(e,'destination_amount')}
                                />
                            )
                        }
                        
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Widget;