import React, { useEffect, useState, Fragment } from "react";
import { Typography } from '@material-ui/core';
import { updateProfile } from './../helpers/manageAccount';
import { updateProfile as updateProfileAction } from './../store/actions/app.js';
import { useSelector, useDispatch } from 'react-redux';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { parseErrors } from './../helpers/parseErrors';
import { useSnackbar } from 'notistack';
import Error from './../components/Error';
import Button from '@material-ui/core/Button';

export default function Profile(props) {

    // state
    const [values, setValues] = useState({
        display_name: '',
        description: ''
    });
    const [submitting, setSubmitting] = useState(false);
    const [is_error_visible, setErrorVisibile] = useState(false);
    const [error, setError] = useState([]);

    // redux
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        if(app.user.profile) {
            setValues({
                display_name: app.user.profile.display_name,
                description: app.user.profile.description
            })
        }
    },[])

    // methods
    const { enqueueSnackbar } = useSnackbar();
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }
    const handleProfileUpdate = async () => {
        setSubmitting(true);
        const [error, response] = await updateProfile(app.user._id,app.token,values);
        setSubmitting(false);
        if(error) {
            const errors = parseErrors(error.response);
            setError(errors);
            setErrorVisibile(true);
            return;
        }
        enqueueSnackbar('Success! Your profile has been updated.', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });

        dispatch( updateProfileAction(response.data) );
    }

    return (
        <Fragment>

            <Typography 
                variant="h5"
                className="mb-2"
            >
                <strong>Profile</strong>
            </Typography>
            
            <div className="row mt-3">
                <div className="col-md-8 col-12">
                    {
                        is_error_visible ? <Error error={error} /> : null
                    }
                    <ValidatorForm
                        onSubmit={handleProfileUpdate}
                        noValidate
                    >

                        <TextValidator
                            variant="outlined"
                            fullWidth
                            name="display_name"
                            label="Display Name"
                            type="text"
                            id="display_name"
                            autoComplete="display_name"
                            className="mb-3"
                            value={values.display_name}
                            onChange={(event) => handleInputChange(event,'display_name')}
                        />

                        <TextValidator
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            name="description"
                            label="Bio"
                            type="text"
                            id="description"
                            autoComplete="description"
                            className="mb-3"
                            value={values.description}
                            onChange={(event) => handleInputChange(event,'description')}
                        />

                        <Button 
                            disabled={submitting}
                            size="large" 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            >Submit
                        </Button>
                    </ValidatorForm>
                </div>
            </div>

        </Fragment>
    )

}
