import axios from "axios";
import config from "../config";

/**
 * Manage user
 */
export const changePassword = async (id,token,password) => {
  try {
    let response = await axios({
      method: "put",
      url: `${config.SERVER_URL}/users/${id}`,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data: {
        password: password
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const updateProfile = async (id,token,data) => {
  try {
    let response = await axios({
      method: "put",
      url: `${config.SERVER_URL}/profiles/${id}`,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data: data
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};
