import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'moment-timezone';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import store from './store';

// Styling
import theme from './theme';
import './sass/bootstrap.scss';
import './sass/App.scss';

// Components
import Bootstrap from './Bootstrap';
import Home from './Home';
import Tracker from './GoogleAnalytics';
import Signup from './Signup';
import AccountConfirmation from './AccountConfirmation';
import Login from './Login';
import Forgot from './Forgot';
import Reset from './Reset';
import Settings from './Settings';
import Swap from './Swap';
import Stake from './Stake';

ReactDOM.render((
	<Provider store={store}>
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<SnackbarProvider maxSnack={3}>
			<Router>
				<Switch>
					<Route exact path="/swap" component={ Tracker(Swap) } />
					<Route exact path="/signup" component={ Tracker(Signup) } />
					<Route exact path="/login" component={ Tracker(Login) } />
					<Route exact path="/confirmation" component={ Tracker(AccountConfirmation) } />
					<Route exact path="/forgot-password" component={ Tracker(Forgot) } />
					<Route exact path="/reset-password/:code" component={ Tracker(Reset) } />
					<Bootstrap>
						<Route exact path="/" component={ Tracker(Home) } />
						<Switch>
							<Route exact path="/settings/:section" component={ Tracker(Settings) } />
							<Route exact path="/stake/:symbol" component={ Tracker(Stake) } />
						</Switch>
					</Bootstrap>
				</Switch>
			</Router>
			</SnackbarProvider>
		</MuiThemeProvider>
	</Provider>
), document.getElementById('root'));