import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { resetPassword } from './helpers/resetPassword';
import { parseErrors } from './helpers/parseErrors';
import Error from './components/Error';
import { useSnackbar } from 'notistack';

export default function Reset(props) {

    // state
    const [values, setValues] = useState({
        password: ''
    });
    const [submitting, setSubmitting] = useState(false);
    const [is_error_visible, setErrorVisibile] = useState(false);
    const [error, setError] = useState([]);

    // methods
    const { enqueueSnackbar } = useSnackbar();

    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }

    const handleSubmit = async () => {

        setSubmitting(true);
        setErrorVisibile(false);

        const [error, response] = await resetPassword(props.match.params.code, values.password);
        setSubmitting(false);

        if(error) {   
            const errors = parseErrors(error.response);
            setError(errors);
            setErrorVisibile(true);
            return;
        }

        enqueueSnackbar('Your password has been reset! You can now login.', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });

        props.history.push('/login');

    }

    return (
        <div className="container h-100">
           <div className="row h-100 justify-content-center align-items-center">
                <div className="col-md-6 col-12">

                    {
                        is_error_visible ? <Error error={error} /> : null
                    }
                    <ValidatorForm
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <TextValidator
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            className="mb-3"
                            value={values.password}
                            onChange={(event) => handleInputChange(event,'password')}
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />

                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <Button 
                                    disabled={submitting}
                                    size="large" 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit">Reset Password</Button>
                            </div>
                            <div>
                                <Link to="/login">No, I am good</Link>
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
           </div>
       </div>
    )

}
