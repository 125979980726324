import React, { Fragment, useEffect, useContext } from "react";
import { 
    Typography, 
    Divider
} from "@material-ui/core";
import { AppContext } from './context';
import { stringShortener } from './../../helpers/metamask';

const TransactionLog = (props) => {

    // context
    const {state, dispatch} = useContext(AppContext);

    // lifecycle
    useEffect(() => {
        console.log("State of transaction",state.transaction)
    },[state.transaction])

    return (
        <Fragment>
            <Typography variant="h5" className="mt-3">
                Transaction
            </Typography>
            <div className="row mt-2">
                <div className="col-md-4 col-4">
                    <Typography variant="body1" className="key">
                        TX Hash:
                    </Typography>
                </div>

                <div className="col-md-8 col-8 text-right text-md-left">
                    <Typography variant="body1" className="value">
                        { stringShortener(state.transaction.transactionHash,10) }
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-4">
                    <Typography variant="body1" className="key">
                        Confirmations:
                    </Typography>
                </div>
                <div className="col-md-8 col-8 text-right text-md-left">
                    <Typography variant="body1" className="value">
                        {state.transaction.confirmation}
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-4">
                    <Typography variant="body1" className="key">
                        Status:
                    </Typography>
                </div>
                <div className="col-md-8 col-8 text-right text-md-left">
                    <Typography variant="body1" className="value">
                        {
                            (state.transaction.completed || state.transaction.confirmation >= 2) && "Completed"
                        }
                        {
                            (state.transaction.started || state.transaction.confirmation < 2) && "Pending"
                        }
                    </Typography>
                </div>
            </div>
            <Divider className="mt-3"/>
        </Fragment>
    )
}

export default TransactionLog;