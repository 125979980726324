import React, { useEffect, useState, Fragment } from "react";
import { Typography } from '@material-ui/core';
import { changePassword } from './../helpers/manageAccount';
import { useSelector, useDispatch } from 'react-redux';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { parseErrors } from './../helpers/parseErrors';
import { useSnackbar } from 'notistack';
import Error from './../components/Error';
import Button from '@material-ui/core/Button';

export default function Security(props) {

    // state
    const [values, setValues] = useState({
        password: '',
        confirm_password: ''
    });
    const [submitting, setSubmitting] = useState(false);
    const [is_error_visible, setErrorVisibile] = useState(false);
    const [error, setError] = useState([]);

    // redux
    const app = useSelector(state => state.app);

    // lifecycles
    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if(value === values.password) return true
            return false
        });
    })

    // methods
    const { enqueueSnackbar } = useSnackbar();
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }
    const handleChangePassword = async () => {

        setSubmitting(true);
        const [error, response] = await changePassword(app.user._id,app.token,values.password);
        setSubmitting(false);
        if(error) {
            const errors = parseErrors(error.response);
            setError(errors);
            setErrorVisibile(true);
            return;
        }
        enqueueSnackbar('Success! Your password has been updated.', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });

        setValues({
            password: '',
            confirm_password: ''
        });

    }

    return (
        <Fragment>

            <Typography 
                variant="h5"
            >
                <strong>Security</strong>
            </Typography>

            <div className="row mt-3">
                <div className="col-md-8 col-12">
                    {
                        is_error_visible ? <Error error={error} /> : null
                    }
                    <ValidatorForm
                        onSubmit={handleChangePassword}
                        noValidate
                    >

                        <TextValidator
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="New password"
                            type="password"
                            id="password"
                            autoComplete="new_password"
                            className="mb-3"
                            value={values.password}
                            onChange={(event) => handleInputChange(event,'password')}
                            validators={['required']}
                            errorMessages={['This field is required','Your passwords must match']}
                        />

                        <TextValidator
                            variant="outlined"
                            required
                            fullWidth
                            name="confirm_password"
                            label="Confirm new password"
                            type="password"
                            id="confirm_password"
                            autoComplete="confirm_new_password"
                            className="mb-3"
                            value={values.confirm_password}
                            onChange={(event) => handleInputChange(event,'confirm_password')}
                            validators={['required','isPasswordMatch']}
                            errorMessages={['This field is required','Your passwords must match']}
                        />

                        <Button 
                            disabled={submitting}
                            size="large" 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            >Submit
                        </Button>
                    </ValidatorForm>
                </div>
            </div>
            
        </Fragment>
    )

}
