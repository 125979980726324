import React from "react";
import { Typography, Button, Divider } from '@material-ui/core';
import './../../sass/About.scss';
import summary from './../../assets/Flurbo_Executive_Summary.pdf';

const About = (props) => {
    return (
        <>
            <div className="container about">
                <div className="row about">
                    <div className="col-12">
                        
                        <div className="breakaway">
                            <div className="row">
                                <div className="col-md-7 col-12">
                                    <div className="sep"></div>
                                    <Typography variant="h3" className="overlapping-header">
                                        Flurbo takes the "technical mumbo jumbo" out of making informed decisions in crypto asset investing.
                                    </Typography>
                                </div>
                            </div>
                            
                            <Typography variant="body1" className="">
                                For institutional investors who seek to invest crypto assets for passive returns with mitigated risks, Flurbo systematically identifies opportunities that best suit each investor’s risk tolerance profile. Flurbo offers turnkey staking solutions and leverages artificial intelligence to locate lending, trading, and DeFi (“decentralized finance”) opportunities according to a client’s criteria.
                            </Typography>
                            <Typography className="">
                                Just as travellers can appreciate the simplicity of the latter, institutional crypto investors will be able to appreciate the simplicity of Flurbo’s “plug and play” recommendation engine which can lead them to investment and trading ideas that match their criteria.
                            </Typography>
                            <Button href={summary} target="_blank" variant="contained" color="primary" size="large" className="mt-5">
                                Executive Summary
                            </Button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
    /*
    return (
        <div className="container about">
            <div className="row about">
                <div className="col-md-8 col-12">
                    <Typography variant="h3" className="overlapping-header ml-md-5">
                        Flurbo takes the "technical mumbo jumbo" out of making informed decisions in crypto asset investing.
                    </Typography>
                </div>
                <div className="col-12 breakaway">
                    <div className="pl-5 pr-5">
                        <Typography variant="body1" className="pl-md-5 pr-md-4 mb-3">
                            For institutional investors who seek to invest crypto assets for passive returns with mitigated risks, Flurbo systematically identifies opportunities that best suit each investor’s risk tolerance profile. Flurbo offers turnkey staking solutions and leverages artificial intelligence to locate lending, trading, and DeFi (“decentralized finance”) opportunities according to a client’s criteria.
                        </Typography>
                        <Typography className="pl-md-5 pr-md-4 mb-5">
                            Just as travellers can appreciate the simplicity of the latter, institutional crypto investors will be able to appreciate the simplicity of Flurbo’s “plug and play” recommendation engine which can lead them to investment and trading ideas that match their criteria.
                        </Typography>
                        <Button onClick={() => props.history.push('/signup')} variant="contained" color="primary" size="large" className="ml-5">
                            Get Started
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
    */
}

export default About;
