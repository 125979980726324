import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link, NavLink } from 'react-router-dom';
import { AppContext } from './context';
import { getWeb3, connectToMetamask, getMetamask, getAddress, stringShortener } from './../../helpers/metamask';
import { 
    Toolbar,
    AppBar,
    Button ,
    Tooltip
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import logo from './../../assets/images/logo.png';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
));

const SwapHeader = (props) => {

    // state
    const [web3, setWeb3] = useState(null);
    const [metamask, setMetamask] = useState(null);

    // context
    const {state, dispatch} = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    // Metamask events
    if(window.ethereum) {
        window.ethereum.on('accountsChanged', (accounts) => {

            if(accounts.length === 0 && state.address) {
                enqueueSnackbar('Attention! Metamask was disconnected.', { 
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
                dispatch({ 
                    type: 'SET_ADDRESS', 
                    payload: ''
                });
            }

        });
    }

    // lifecycle
    useEffect(() => {
        console.log("State of Swap Header", state)
    },[state]);

    useEffect(() => {

        let isMetamaskReady = getMetamask(window);
        setMetamask(isMetamaskReady);

        let Web3 = getWeb3();
        setWeb3( new Web3(Web3.givenProvider || "http://localhost:8545") )

    },[]);

    useEffect(() => {

        if(metamask === null) {
            console.info("Metamask is loading...")
        } else if(metamask === false) {
            console.error("Metamask not found:(")
        } else {
            console.log("Metamask is ready!", metamask)
            dispatch({ 
                type: 'SET_METAMASK', 
                payload: metamask
            });
        }
    
    },[metamask]);

    useEffect(() => {
        
        if(web3 === null) {
            console.info("web3 is loading...")
        } else if(web3 === false) {
            console.error("web3.js not found:(")
        } else {
            dispatch({ 
                type: 'SET_WEB3', 
                payload: web3
            });
            if(metamask) {
                handleGetAddress(web3);
            }   
        }
    
    },[web3]);

    // methods
    const handleGetAddress = async (web3) => {
        const user_address = await getAddress(web3);
        if(user_address.length > 0) {
            //setAddress(user_address[0]);
            dispatch({ 
                type: 'SET_ADDRESS', 
                payload: user_address[0]
            });
        }
    }
    
    const handleConnectToMetamask = async () => {
        try {
            let connect = await connectToMetamask(window);
            if(connect.length > 0) {
                //setAddress(connect[0]);
                dispatch({ 
                    type: 'SET_ADDRESS', 
                    payload: connect[0]
                });
            }
        } catch(error) {
            enqueueSnackbar('Error! Could not connect to MetaMask.', { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
        }
    }

    return (
        <AppBar 
            position="static" 
            color="transparent" 
            elevation={0}
            className="swap-header"
            >
            <Toolbar disableGutters={true}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-3 d-flex align-items-center">
                            <Link to="/"><img src={logo} className="logo-image"/></Link>
                            <div className="logo-copy"><Link to="/">Flurbo</Link></div>
                        </div>
                        <div className="col-md-6 col-9 text-right d-flex justify-content-end align-items-center">

                            {
                                state.address ? (
                                    <div className="address-wrapper d-flex align-items-center">
                                        <Jazzicon diameter={25} seed={jsNumberForAddress(state.address)} />
                                        <Tooltip title={state.address} arrow>
                                            <span>{ stringShortener(state.address,5) }</span>
                                        </Tooltip>
                                        
                                    </div>
                                ) : (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={handleConnectToMetamask}
                                    >
                                        Connect MetaMask
                                    </Button>
                                )
                            }
                            
                            <Button 
                                component={ForwardNavLink}
                                to="/"
                                size="small"
                                className="button-link ml-2"
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
}


export default SwapHeader;