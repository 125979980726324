import polymath from './../assets/images/assets/polymath.png';
import celo from './../assets/images/assets/celo.png';
import polkadot from './../assets/images/assets/polkadot.png';
import tron from './../assets/images/assets/tron.png';
import ada from './../assets/images/assets/ada.png';
import tezos from './../assets/images/assets/tezos.png';
import cosmos from './../assets/images/assets/cosmos.png';
import keep from './../assets/images/assets/keep.png';
import synthetix from './../assets/images/assets/snx.png';

const assets = [{
    name: 'Polymath',
    icon: polymath,
    class: 'polymath',
    symbol: 'POLY',
    description: 'Polymath (POLY) is creating a global platform for issuing and investing in securities tokens.'
},{
    name: 'Celo',
    icon: celo,
    symbol: 'CGLD',
    class: 'celo',
    description: 'Celo describes itself as an open platform that makes financial tools accessible to anyone with a mobile phone.'
},{
    name: 'Polkadot',
    icon: polkadot,
    symbol: 'DOT',
    class: 'polkadot',
    description: 'Polkadot is a sharded protocol that enables blockchain networks to operate together.'
},{
    name: 'Tron',
    icon: tron,
    symbol: 'TRX',
    class: 'tron',
    description: 'TRON is one of the largest blockchain-based operating systems in the world.'
},{
    name: 'Cardano',
    icon: ada,
    class: 'ada',
    symbol: 'ADA',
    description: 'Cardano is a decentralized platform that will allow complex programmable transfers of value in a secure and scalable fashion.'
},{
    name: 'Tezos',
    icon: tezos,
    symbol: 'XTZ',
    class: 'tezos',
    description: 'Tezos (XTZ) is a multi-purpose platform that supports decentralized applications (DApps) and smart contracts.'
},{
    name: 'Cosmos',
    icon: cosmos,
    symbol: 'ATOM',
    class: 'cosmos',
    description: 'Cosmos is a decentralized network of independent parallel blockchains, each powered by BFT consensus algorithms like Tendermint consensus.'
},{
    name: 'Keep',
    icon: keep,
    symbol: 'KEEP',
    class: 'keep',
    description: 'Keep is building infrastructure for autonomous private data on public blockchains, DApps, and DAOs.'
},{
    name: 'Synthetix',
    icon: synthetix,
    symbol: 'SNX',
    class: 'synthetix',
    description: 'Synthetix is a derivatives liquidity protocol on Ethereum that enables the issuance and trading of synthetic assets.'
}]

export default assets;