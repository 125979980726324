import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { forgotPassword } from './helpers/forgotPassword';
import { parseErrors } from './helpers/parseErrors';
import Error from './components/Error';
import { useSnackbar } from 'notistack';

export default function Forgot(props) {

    // state
    const [values, setValues] = useState({
        email: ''
    });
    const [submitting, setSubmitting] = useState(false);
    const [is_error_visible, setErrorVisibile] = useState(false);
    const [error, setError] = useState([]);

    // methods

    const { enqueueSnackbar } = useSnackbar();
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }

    const handleSubmit = async () => {

        setSubmitting(true);
        setErrorVisibile(false);

        const [error, response] = await forgotPassword(values.email);
        setSubmitting(false);

        if(error) {   
            const errors = parseErrors(error.response);
            setError(errors);
            setErrorVisibile(true);
            return;
        }

        enqueueSnackbar('Success! Check your email and follow the instruction to reset your password.', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });

        setValues({...values, email: ''});

    }

    return (
        <div className="container h-100">
           <div className="row h-100 justify-content-center align-items-center">
                <div className="col-md-6 col-12">

                    {
                        is_error_visible ? <Error error={error} /> : null
                    }
                    <ValidatorForm
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <TextValidator
                            autoComplete="email"
                            name="email"
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            className="mb-3"
                            autoFocus
                            value={values.email}
                            onChange={(event) => handleInputChange(event,'email')}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'Please enter a valid e-mail address']}
                        />

                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <Button 
                                    disabled={submitting}
                                    size="large" 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit">Submit</Button>
                            </div>
                            <div>
                                <Link to="/login">No, I am good</Link>
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
           </div>
       </div>
    )

}
