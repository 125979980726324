import React, { useState, Fragment } from "react";
import { 
    Divider, 
    IconButton,
    Toolbar,
    AppBar 
} from '@material-ui/core';
import { withRouter } from "react-router";
import { NavLink, Link } from 'react-router-dom';
import './../sass/Navigation.scss';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Logout } from './../store/actions/app.js';
import logo from './../assets/images/logo.png';
import logo_alt from './../assets/images/logo-alt.png';
import Hero from './Splash/Hero';

const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
));

const Navigation = (props) => {

    // state
    const [anchorEl, setAnchorEl] = useState(null);

    // redux
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();

    // methods
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch( Logout() );
    };

    return (
        <Fragment>
            <AppBar 
                position="static" 
                color="transparent" 
                elevation={0}
                className={props.location.pathname.match(/stake/g) ? 'inverted' : ''}
                >
                <Toolbar disableGutters={true}>
                    <div className="container-fluid">
                        <div className="row app-header">
                            <div className="col-md-6 col-3 d-flex align-items-center">
                                <Link to="/"><img src={logo} className="logo-image regular"/></Link>
                                <Link to="/"><img src={logo_alt} className="logo-image alt"/></Link>
                                <div className="logo-copy"><Link to="/">Flurbo</Link></div>
                            </div>
                            <div className="col-md-6 col-9 text-right menu">
                                <Button 
                                    component={ForwardNavLink}
                                    to="/swap"
                                    className="button-link"
                                    >
                                    Coin Swap
                                </Button>
                                
                                <IconButton
                                    onClick={handleClick}
                                    className="d-inline-block d-md-none"
                                >
                                    <MenuIcon/>
                                </IconButton>

                                {
                                    !app.loggedin ? (
                                        <Fragment>
                                            <Menu
                                                anchorEl={anchorEl}
                                                keepMounted={false}
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem 
                                                    component={ForwardNavLink}
                                                    to="/login"
                                                    onClick={handleClose}
                                                    >
                                                    Login
                                                </MenuItem>
                                                <MenuItem 
                                                    component={ForwardNavLink}
                                                    to="/signup"
                                                    onClick={handleClose}
                                                    >
                                                    Sign Up
                                                </MenuItem>
                                            </Menu>
                                            <span className="d-none d-md-inline-block">
                                                <Button 
                                                    component={ForwardNavLink}
                                                    to="/login"
                                                    color="default" 
                                                    className="button-link mx-2">
                                                        Login
                                                </Button>
                                                <Button
                                                    className="button-link"
                                                    component={ForwardNavLink}
                                                    to="/signup"
                                                    color="primary"
                                                    variant="contained"
                                                >
                                                    Sign up
                                                </Button>
                                            </span>
                                        </Fragment>
                                    ) : (
                                        <Menu
                                            anchorEl={anchorEl}
                                            keepMounted={false}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem 
                                                component={ForwardNavLink}
                                                to="/settings/profile"
                                                onClick={handleClose}
                                                >
                                                Profile
                                            </MenuItem>
                                            <MenuItem 
                                                component={ForwardNavLink}
                                                to="/settings/security"
                                                onClick={handleClose}
                                                >
                                                Security
                                            </MenuItem>
                                            <Divider/>
                                            <MenuItem 
                                                onClick={() => {
                                                handleClose();
                                                handleLogout();
                                            }}>
                                                Logout
                                            </MenuItem>
                                        </Menu>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            {
                props.location.pathname === "/" && <Hero {...props}/>
            }
        </Fragment>
        
    )

}

export default withRouter(Navigation);