import React from "react";
import { Typography, Button, Avatar } from '@material-ui/core';
import Masonry from 'react-masonry-css';
import './../../sass/Assets.scss';
import assets from './../../local_data/assets.js';

const Assets = (props) => {

    const handleStake = (symbol) => {
        console.log("Props", props)
        props.history.push('/stake/' + symbol);
    }
    return (
        <div className="assets">
            <div className="container">
                <div className="row">
                    <div className="col-12 heading">
                        <Typography variant="h3">
                            Managed Assets
                        </Typography>
                        <Typography variant="body1">
                            Industry leading you can stake<br/>and earn interest.
                        </Typography>
                    </div>
                </div>
                <div className="row list ml-0">
                    <Masonry
                        breakpointCols={{
                            default: 3,
                            1400: 3,
                            1200: 3,
                            927: 2,
                            844: 1
                        }}
                        className="masonry-grid d-flex"
                        columnClassName="masonry-grid-column"
                    >
                    {
                        assets.map((token) =>
                            <div className="masonry-item mr-3" key={Math.random()}>
                                <div className="asset px-4 py-4 d-flex">

                                    <div className="mr-3">
                                        <Avatar 
                                            className={token.class ? token.class + ' avatar' : 'avatar' }
                                            src={token.icon}
                                        />
                                    </div>
                                    <div>
                                        <Typography variant="h4">
                                            {token.name}
                                        </Typography>
                                        <Typography variant="body2">
                                            {token.description}
                                        </Typography>
                                        {/*
                                        <Button 
                                            color="primary" 
                                            variant="contained" 
                                            className="stake-button"
                                            onClick={() => handleStake(token.symbol)}
                                            >
                                            Stake
                                        </Button>
                                        */}
                                    </div>
                                    
                                </div>
                            </div>
                        )
                    }
                    </Masonry>
                </div>
            </div>
        </div>
    )
}

export default Assets;
