import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { loginUser } from './helpers/loginUser';
import { parseErrors } from './helpers/parseErrors';
import Error from './components/Error';
import { setAuthToken, setUser } from './store/actions/app.js';
import { useSelector, useDispatch } from 'react-redux';

export default function Login(props) {

    // state
    const [values, setValues] = useState({
        email: '', 
        password: ''
    });
    const [submitting, setSubmitting] = useState(false);
    const [is_error_visible, setErrorVisibile] = useState(false);
    const [error, setError] = useState([]);

    // redux
    const app = useSelector(state => state.app);
	const dispatch = useDispatch();

    // methods
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }

    const handleSubmit = async () => {

        setSubmitting(true);
        setErrorVisibile(false);

        const [error, response] = await loginUser(values.email,values.password);
        setSubmitting(false);

        if(error) {   
            const errors = parseErrors(error.response);
            setError(errors);
            setErrorVisibile(true);
            return;
        }

        dispatch( setAuthToken(response.data.jwt) );
        dispatch( setUser(response.data.user) );

        props.history.push('/');

    }

    return (
        <div className="container h-100">
           <div className="row h-100 justify-content-center align-items-center">
                <div className="col-md-6 col-12">

                    {
                        is_error_visible ? <Error error={error} /> : null
                    }
                    <ValidatorForm
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <TextValidator
                            autoComplete="email"
                            name="email"
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            className="mb-3"
                            autoFocus
                            value={values.email}
                            onChange={(event) => handleInputChange(event,'email')}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'Please enter a valid e-mail address']}
                        />

                        <TextValidator
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            className="mb-3"
                            value={values.password}
                            onChange={(event) => handleInputChange(event,'password')}
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />

                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <Button 
                                    disabled={submitting}
                                    size="large" 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit">Login</Button>
                            </div>
                            <div>
                                <Link to="/signup">New account</Link> <Link className="ml-3" to="/forgot-password">Forgot password?</Link>
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
           </div>
       </div>
    )

}
